import React, { useMemo } from "react";
import styles from "./SideBar.module.scss";
import customerIcon from "src/assets/customer.svg";
import merchantIcon from "src/assets/merchant.svg";
import starIcon from "src/assets/star.svg";
import invoiceIcon from "src/assets/invoice.svg";
import mergeIcon from "src/assets/merge.svg";
import videoIcon from "src/assets/video.svg";
import { OverflowEllipsis, Stack } from "shared/components";
import { Container } from "./Container";
import { ContainerSegment } from "./ContainerSegment";
import icon96 from "src/assets/icon96.png";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { TokenClaims } from "shared/lib/auth";
import { useADLogout } from "src/features/auth/api/useADLogout";
import { ContainerListButton } from "./ContainerListButton";

export const SideBar = (): JSX.Element => {
	const { state } = useUserAuthState();
	const adLogout = useADLogout();

	const tokenClaims = useMemo(() => {
		if (!state.accessToken) {
			return null;
		}

		const tokenClaims = new TokenClaims(state.accessToken);
		return {
			name: tokenClaims["name"] as string,
			email: tokenClaims["email"] as string
		};
	}, [state.accessToken]);

	return (
		<Stack gap={32}>
			<Container>
				<ContainerSegment>
					<div className={styles.sideBarHeader}>
						<img src={icon96} />
						<div>
							<OverflowEllipsis>Raven</OverflowEllipsis>
							<OverflowEllipsis>The Rave Admin Portal</OverflowEllipsis>
						</div>
					</div>
				</ContainerSegment>
			</Container>

			{
				tokenClaims &&
				<Container>
					<ContainerSegment>
						<div className={styles.sideBarUser}>
							<div>
								<OverflowEllipsis>{tokenClaims.name}</OverflowEllipsis>
								<OverflowEllipsis>{tokenClaims.email}</OverflowEllipsis>
							</div>
							<a onClick={adLogout}>Logout</a>
						</div>
					</ContainerSegment>
				</Container>
			}

			<Container>
				<ContainerListButton icon={customerIcon} text="Customer Search" link="/customers" />
				<ContainerListButton icon={starIcon} text="Influencer Discovery" link="/influencer-discovery" />
				<ContainerListButton icon={invoiceIcon} text="Manual Invoices" link="/manual-invoice" />
				<ContainerListButton icon={merchantIcon} text="Merchants" link="/merchants" />
				<ContainerListButton icon={mergeIcon} text="Merge Customers" link="/customers/merge" />
				<ContainerListButton icon={videoIcon} text="Ugc" link="/ugc" />
			</Container>
		</Stack>
	);
};

import React, { useMemo } from "react";
import styles from "./StatsBar.module.scss";

export interface StatsBarProps {
	readonly createdCount: number;
	readonly queryingCount: number;
	readonly errorCount: number;
	readonly completeCount: number;
}

export const StatsBar = ({ createdCount, queryingCount, errorCount, completeCount }: StatsBarProps): JSX.Element => {
	const [createdWidth, queryingWidth, errorWidth, completeWidth] = useMemo(() => {
		const total = createdCount + queryingCount + errorCount + completeCount;
		const createdWidth = (createdCount / total) * 100;
		const queryingWidth = (queryingCount / total) * 100;
		const errorWidth = (errorCount / total) * 100;
		const completeWidth = (completeCount / total) * 100;
		return [createdWidth, queryingWidth, errorWidth, completeWidth];
	}, [createdCount, queryingCount, errorCount, completeCount]);

	return (
		<div className={styles.statsBar}>
			<div className={styles.error} style={{ width: `${errorWidth}%` }}></div>
			<div className={styles.complete} style={{ width: `${completeWidth}%` }}></div>
			<div className={styles.querying} style={{ width: `${queryingWidth}%` }}></div>
			<div className={styles.created} style={{ width: `${createdWidth}%` }}></div>
		</div>
	);
};

import React from "react";
import { Link } from "react-router-dom";
import { Center, ErrorDisplay, InfiniteScroller, Loader, SmartBox, Stack } from "shared/components";
import { useTitle } from "shared/hooks";
import { Container } from "src/lib/components/Container";
import { ContainerSegment } from "src/lib/components/ContainerSegment";
import { Limit } from "src/lib/components/Limit";
import { SlowLoader } from "src/lib/components/SlowLoader";
import { StyledTable } from "src/lib/components/StyledTable";
import { StyledTableHeader } from "src/lib/components/StyledTableHeader";
import { TitleBar } from "src/lib/components/TitleBar";
import { ListInfluencerDiscoveryArchivesResponseModel, useInfluencerDiscoveryArchives } from "../api/useInfluencerDiscoveryArchives";
import dayjs from "dayjs";
import { Tag, TagColor } from "src/lib/components/Tag";
import { useSubmitInfluencerDiscoveryArchive } from "../api/useSubmitInfluencerDiscoveryArchive";

export const PageInfluencerDiscovery = (): JSX.Element => {
	useTitle("Influencer Discovery");

	const influencerDiscoveryArchives = useInfluencerDiscoveryArchives();
	const submitInfluencerDiscoveryArchive = useSubmitInfluencerDiscoveryArchive();

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files || e.target.files.length !== 1) {
			return;
		}

		const file = e.target.files[0];
		submitInfluencerDiscoveryArchive.mutate(file);
	};

	return (
		<Limit>
			<TitleBar title="Influencer Discovery" />

			<Stack>
				<Container>
					<ContainerSegment>
						<b>New Archive</b><br />
						Upload a CSV archive to begin a new influencer discovery
						process.
					</ContainerSegment>
					{
						submitInfluencerDiscoveryArchive.error &&
						<ContainerSegment>
							<ErrorDisplay problemResponse={submitInfluencerDiscoveryArchive.error} />
						</ContainerSegment>
					}
					<ContainerSegment>
						{
							submitInfluencerDiscoveryArchive.isLoading
								? <Loader />
								: <input type="file" onChange={onChange} multiple={false} />
						}
					</ContainerSegment>
				</Container>

				<Container>
					<SmartBox
						switchOn={influencerDiscoveryArchives}
						loading={() => <ContainerSegment><Center><Loader /></Center></ContainerSegment>}
						error={err => <ContainerSegment><ErrorDisplay problemResponse={err} /></ContainerSegment>}
						success={data => <ArchiveTable archives={data.pages.flatMap(x => x.results)} />} />
				</Container>

				<InfiniteScroller forQuery={influencerDiscoveryArchives} />
			</Stack>
		</Limit>
	);
};

interface ArchiveTableProps {
	archives: ListInfluencerDiscoveryArchivesResponseModel[];
}

const ArchiveTable = ({ archives }: ArchiveTableProps): JSX.Element => {
	return (
		<StyledTable>
			<thead>
				<tr>
					<StyledTableHeader>Date</StyledTableHeader>
					<StyledTableHeader>Archive Name</StyledTableHeader>
					<StyledTableHeader>Status</StyledTableHeader>
					<StyledTableHeader> </StyledTableHeader>
				</tr>
			</thead>
			<tbody>
				{
					archives.map(x => (
						<tr key={x.archiveId}>
							<td>{dayjs(x.created).format("DD MMM YYYY")}</td>
							<td>{x.name}</td>
							<td>
								{
									x.complete
										? <Tag color={TagColor.Bright} text="Complete" />
										: <SlowLoader />
								}
							</td>
							<td>
								<Link to={`/influencer-discovery/archive/${x.archiveId}`}>View</Link>
							</td>
						</tr>
					))
				}
			</tbody>
		</StyledTable>
	);
};
